<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" xl="12" lg="12">
        <div class="d-flex justify-space-between ">
          <div class="d-flex bordered qp-tab-nav">
            <div class="d-flex p-2">
              <template v-if="checkReadPermission($modules.crossSale.management.slug)">
                <div class="nv_item d-flex p-4 pointer " @click="gotoPage('/offers')">
                  <SvgIcon class="text-xl text-thin-black" text="Packages">
                    <template v-slot:icon>
                      <PackagesIcon/>
                    </template>
                  </SvgIcon>
                </div>
                <v-divider
                    vertical style="background-color: rgba(220, 220, 220, 1)"
                ></v-divider>
              </template>

              <template v-if="checkReadPermission($modules.crossSale.management.slug)">
                <div class="nv_item d-flex p-4 pointer " @click="gotoPage('/suggestions')">
                  <SvgIcon class="text-xl  text-thin-black" text="Suggestions">
                    <template v-slot:icon>
                      <SuggestionsIcon/>
                    </template>
                  </SvgIcon>
                </div>
                <v-divider
                    vertical style="background-color: rgba(220, 220, 220, 1)"
                ></v-divider>
              </template>

              <template v-if="checkReadPermission($modules.crossSale.management.slug)">
                <div class="nv_item d-flex p-4 pointer " @click="gotoPage('/cross-sale')">
                  <SvgIcon class="text-xl qp-tab-nav-is-active " text="Cross sale">
                    <template v-slot:icon>
                      <CrossSaleIcon/>
                    </template>
                  </SvgIcon>
                </div>
                <v-divider
                    vertical style="background-color: rgba(220, 220, 220, 1)"
                ></v-divider>
              </template>
              <template v-if="checkWritePermission($modules.offers.management.slug)">
                <div class="nv_item d-flex p-4 pointer ">
                  <SvgIcon class="text-xl text-thin-black  " text="Configuration">
                    <template v-slot:icon>
                      <ConfigIcon/>
                    </template>
                  </SvgIcon>
                </div>
              </template>
            </div>
          </div>
          <div class="d-flex gap-x-2">
            <v-btn
                color=" darken-1"
                class="white--text blue-color ml-1"
                text
                height="48"
                v-if="checkWritePermission($modules.crossSale.management.slug)"
                @click="addSuggestion"
            >
              <AddIcon/>
              <span class="ml-1">Add Cross-sale</span>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-divider class="mt-4" style="border-color: rgba(17, 42, 70, 0.14) !important;"/>

    <div class="mt-4 pb-4" v-for="(activeCrossSale, i) in suggestions" :key="i">

      <h3 class="heading_package">{{ activeCrossSale.name }}</h3>
      <div class="d-flex align-center flex-wrap gap-x-8 gap-y-6">
        <v-card style="border-radius: 8px" class="shadow suggestions_package_card p-0">
          <v-card-text class=" p-0">
            <div class="p-4 ">
              <div class="mt-2 pt-0 pb-1 height-text">
                <p class="property_name mb-0">Venue</p>
                <p class="property_value mt-0 mb-1 line-clamp-1">{{ activeCrossSale.venueName }}</p>
              </div>
              <div class="mt-1 py-0 pb-1 height-text inner_text_first_package_card">
                <p class="property_name mb-0">Type</p>
                <p class="property_value mt-0 mb-1">{{
                    activeCrossSale.productTypeName ? activeCrossSale.productTypeName : activeCrossSale.productType
                  }}</p>
              </div>

              <div class="mt-1 py-0 height-text inner_text_first_package_card">
                <p class="property_name mb-0">Product</p>
                <p class="property_value mt-0 mb-2">{{ activeCrossSale.productName }}</p>
              </div>
            </div>

            <div class="d-flex justify-center align-center package_card_footer py-2" style="width: 100%">
              {{ activeCrossSale.price | toCurrency }}
            </div>

          </v-card-text>

        </v-card>
        <Suggestions
            v-for="(product,ind) in activeCrossSale.products"
            :key="ind"
            :product="product"
            :index="i+1"
        />
        <div class="last_card d-flex justify-center align-center"
             v-if="checkWritePermission($modules.crossSale.management.slug)">
          <v-btn
              elevation="0"
              color="white"
              class="teal--text"
              fab
              tile
              @click="editSuggestionsFunc(i)"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>

        </div>
      </div>

    </div>

    <div class="  d-flex align-center justify-center" style="width: 250px; margin: 200px auto 0;"
         v-if="suggestions.length === 0">
      <v-col md="12">
        <v-img
            src="../../assets/images/offers_icons/no_cross_sale.png"
        ></v-img>
      </v-col>
    </div>


    <SuggestionsModal
        :showModal="suggestionsModal"
        :id="suggestionId"
        :editSuggestions="editSuggestions"
        @close="closeSuggestionsModal"
        @save="save"
    />
  </v-container>
</template>
<script>
import ConfigIcon from "@/assets/images/trainers/cog_icon.svg";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import PackagesIcon from "@/assets/images/offers_icons/packages.svg";
import SuggestionsIcon from "@/assets/images/offers_icons/suggestions.svg";
import CrossSaleIcon from "@/assets/images/offers_icons/cross_sale.svg";
import AddIcon from "@/assets/images/misc/plus-icon.svg";
import SuggestionsModal from "@/components/Suggestions/SuggestionsModal.vue";
import Suggestions from "@/components/Suggestions/Suggestions.vue";

export default {
  name: "Offers.vue",
  components: {
    Suggestions,
    SuggestionsModal,
    AddIcon,
    PackagesIcon,
    SuggestionsIcon,
    SvgIcon, ConfigIcon,
    CrossSaleIcon
  },
  data() {
    return {
      suggestionsModal: false,
      suggestionId: null,
      editSuggestions: {},
      suggestions: [
        // {
        //   name: 'Suggestion 1',
        //   venueName: 'ZSC',
        //   productType: 'Facility',
        //   productName: 'Day Pass',
        //   price: 150,
        //   products: [
        //     {
        //       venueId:4,
        //       venueName: 'Qasr Al Muwaiji',
        //       productType: 'Facility',
        //       name: 'Day Pass',
        //       price: 150,
        //     },
        //     {
        //       venueId:3,
        //       venueName: 'Al Ain palace museum',
        //       productType: 'Facility',
        //       name: 'Guided Tour',
        //       price: 200,
        //     },
        //     {
        //       venueId:3,
        //       venueName: 'Al Ain palace museum',
        //       productType: 'Facility',
        //       name: 'Guided Tour',
        //       price: 200,
        //     },
        //     {
        //       venueId:3,
        //       venueName: 'Al Ain palace museum',
        //       productType: 'Facility',
        //       name: 'Guided Tour',
        //       price: 200,
        //     },
        //     {
        //       venueId:3,
        //       venueName: 'Al Ain palace museum',
        //       productType: 'Facility',
        //       name: 'Guided Tour',
        //       price: 200,
        //     },
        //     {
        //       venueId:3,
        //       venueName: 'Al Ain palace museum',
        //       productType: 'Facility',
        //       name: 'Guided Tour',
        //       price: 200,
        //     },
        //     {
        //       venueId:3,
        //       venueName: 'Al Ain palace museum',
        //       productType: 'Facility',
        //       name: 'Guided Tour',
        //       price: 200,
        //     },
        //   ]
        // },

        // {
        //   name: 'Package 2',
        //   venueName: 'Al Qattara arts center',
        //   productType: 'Facility',
        //   productName: 'Day Pass',
        //   price: 150,
        //   products: [
        //     {
        //       venueId:1,
        //       venueName: 'Bait Muhammad bin Khalifa',
        //       productType: 'Facility',
        //       name: 'Day Pass',
        //       price: 200,
        //     },
        //     {
        //       venueId:2,
        //       venueName: 'Jebel Hafeet desert park',
        //       productType: 'Event',
        //       name: 'Silver',
        //       price: 250,
        //     },
        //   ]
        // }


      ],
    }
  },
  mounted() {
  },
  computed: {},
  watch: {},
  methods: {
    editSuggestionsFunc(ind) {
      this.editSuggestions = this.suggestions[ind];
      console.log(this.editSuggestions);
      this.editSuggestions.index = ind;
      this.suggestionsModal = true;
    },
    gotoPage(route) {
      this.$router.push(route);
    },
    closeSuggestionsModal() {
      this.suggestionId = null;
      this.suggestionsModal = false;
    },
    addSuggestion() {
      this.suggestionsModal = true;
    },
    save(data) {
      if (data.index !== 'undefined' && data.index >= 0) {
        this.suggestions[data.index] = data;
      } else {
        this.suggestions.push(data);
      }
      this.closeSuggestionsModal();
    },
  }
}
</script>
<style scoped lang="scss">
.heading_package {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: left;
  padding-bottom: 4px;
}


.first_package_card {
  margin-top: 5px;
  width: 220px;
  height: 180px;
  background: #4FAEAF08;
  border: 1px solid #4FAEAF;

  .v-card__title {
    color: #4FAEAF;
    padding: 10px;
    border-bottom: 1px solid #4FAEAF;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;
  }

  .inner_text_first_package_card {
    font-size: 14px;
    line-height: 14.52px;
    color: black;
    text-align: left;
  }

  .first_package_card_footer {
    border-top: 1px solid #4FAEAF;
    font-size: 14px;
    font-weight: 600;
    line-height: 14.52px;
    text-align: left;
    padding-bottom: 20px;
  }
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.last_card {
  margin-top: 5px;
  width: 220px;
  height: 180px;
  background: #4FAEAF08;
  border: 1px dashed #4FAEAF;
  border-radius: 1rem;
}

.suggestions_package_card {
  width: 220px;
  min-height: 175px;
  top: 225px;
  left: 507px;
  gap: 0;
  border: 1px solid #4FAEAF;
  background: #4FAEAF08;
  border-radius: 4px 0 0 0;
  position: unset;

  .package_card_footer {
    color: rgba(79, 174, 175, 1);
    background: rgba(79, 174, 175, 0.1);
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: left;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .property_name {
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;

  }

  .property_value {
    font-size: 12px;
    font-weight: 600;
    line-height: 14.52px;
    text-align: left;
  }

  .height-text {
    color: black;
    min-height: 30px;
  }

  .nth_card_footer {
    background: rgba(17, 42, 70, 0.05);
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: left;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .line-clamp-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
</style>